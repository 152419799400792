import React from 'react';
import './Line.css';

function Line({ type, size, backgroundColor = '#98AAD1', style = undefined, showArrowStart = false, showArrowEnd = false }) {
    let lineClass = '';
    let lineStyle = { backgroundColor: backgroundColor }
    if (type === 'vertical') {
        lineClass = 'line-vertical'
        lineStyle['height'] = `${size}px`
    }
    if (type === 'horizontal') {
        lineClass = 'line-horizontal'
        lineStyle['width'] = `${size}px`
    }
    return (
        <div className='line-container' style={{ ...style }}>
            {showArrowStart && (
                <div
                    className={`arrowhead arrow-${type} arrow-start`}
                    style={{
                        backgroundColor: backgroundColor,
                    }}
                />
            )}
            <div className={lineClass} style={{ ...lineStyle }} />
            {showArrowEnd && (
                <div
                    className={`arrowhead arrow-${type} arrow-end`}
                    style={{
                        backgroundColor: backgroundColor,
                    }}
                />
            )}
        </div>
    );
}

export default Line;