import React, { useContext, useState } from 'react';
import DashboardContext from '../../context/DashboardContext'

import { icons } from '../../utils/dictionaryIcons';
import { regions } from '../../utils/regions';
import './Commune.css'
import Toolbar from '../../components/Toolbar/Toolbar';
import CardRegion from '../../components/CardRegion/CardRegions';
import MetropolitanRegionMap from '../../components/Maps/MetropolitanRegionMap';

function Commune() {
    const { changeDashboard, selectedDashboard } = useContext(DashboardContext);
    const [highlightedRegion, setHighlightedRegion] = useState(null);

    const handleHover = (regionId, isHovered) => {
        if (isHovered) {
            setHighlightedRegion(regionId);
        } else {
            setHighlightedRegion(null);
        }
    };

    const region = selectedDashboard.params.region;
    const northSouth = selectedDashboard.params.northSouth;
    const regionData = regions[northSouth];
    const commune = regionData.find((regionObject) => regionObject.id === region);
    const regionCards = commune.branchOffices.map((item) => {
        const params = { ...selectedDashboard.params, commune: item.commune , branchOffice: item.name};
        return <CardRegion title={item.name} imgObject={icons[item.id]} status={'UNK'} regionId={item.commune} onHover={handleHover} onClick={() => changeDashboard('branchOffice', params)} />
    })

    return (
        <div className='commune-panel-view'>
            <Toolbar title={'Estado de internet y Totalpack'} back={true} backOnClick={() => changeDashboard('region', selectedDashboard.params)} />
            <div className='commune-content'>
                <div className='img-map'>
                    <MetropolitanRegionMap highlightedRegion={highlightedRegion} />
                </div>
                <div className='cards-communes'>{regionCards}</div>
            </div>
        </div>
    )
}

export default Commune;