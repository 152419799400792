import React, { useState, useContext } from 'react';
import DashboardContext from '../../context/DashboardContext'
import { icons } from '../../utils/dictionaryIcons';
import { regions } from '../../utils/regions';
import './Main.css'
import Toolbar from '../../components/Toolbar/Toolbar';
import CardRegion from '../../components/CardRegion/CardRegions';
import NorthChileMap from '../../components/Maps/NorthChileMap';
import SouthChileMap from '../../components/Maps/SouthChileMap';

function Main({ }) {
    const { changeDashboard } = useContext(DashboardContext);
    const [highlightedRegion, setHighlightedRegion] = useState(null);

    const handleHover = (regionId, isHovered) => {
        if (isHovered) {
            setHighlightedRegion(regionId);
        } else {
            setHighlightedRegion(null);
        }
    };

    const northSideCards = regions.north.map((item) => {
        const dashboardParams = { northSouth: 'north', region: item.id }
        return <CardRegion title={item.name} imgObject={icons[item.id]} status={'UNK'} regionId={item.id} onHover={handleHover} onClick={() => changeDashboard('region', dashboardParams)} />
    })
    const southSideCards = regions.south.map((item) => {
        const dashboardParams = { northSouth: 'south', region: item.id }
        return <CardRegion title={item.name} imgObject={icons[item.id]} status={'UNK'} regionId={item.id} onHover={handleHover} onClick={() => changeDashboard('region', dashboardParams)} />
    })

    return (
        <div className='main-panel-view'>
            <Toolbar title={'Estado de internet y Totalpack'} />
            <div className='main-content'>
                <div className='north-side'>
                    <div className='img-map'>
                        <NorthChileMap highlightedRegion={highlightedRegion} />
                    </div>
                    <div className='cards-regions'>{northSideCards}</div>
                </div>
                <div className='south-side'>
                    <div className='img-map'>
                        <SouthChileMap highlightedRegion={highlightedRegion} />
                    </div>
                    <div className='cards-regions'>{southSideCards}</div>
                </div>
            </div>
        </div>
    )
}

export default Main;