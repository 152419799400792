// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-region {
    display: flex;
    width: 400px;
    position: relative;
    display: flex;
    background: white;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
}

.card-region:hover {
    border: 2px solid #1BDBCE;
    background-color: #f4f9fc;
}

.card-region .img-region {
    width: 98px;
    height: 98px;
}

.card-region .img-region img {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
}

.card-region .status-region {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 20px;
}

.card-region .content-region {
    margin-left: 10px;
}

.card-region .content-region .title-region {
    font-family: Sansation;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 4%;
    color: #63666ECF;

}`, "",{"version":3,"sources":["webpack://./components/CardRegion/CardRegions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;;AAEpB","sourcesContent":[".card-region {\n    display: flex;\n    width: 400px;\n    position: relative;\n    display: flex;\n    background: white;\n    margin: 10px 0px;\n    padding: 10px;\n    border-radius: 10px;\n}\n\n.card-region:hover {\n    border: 2px solid #1BDBCE;\n    background-color: #f4f9fc;\n}\n\n.card-region .img-region {\n    width: 98px;\n    height: 98px;\n}\n\n.card-region .img-region img {\n    border-radius: 10px;\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n    display: block;\n}\n\n.card-region .status-region {\n    width: 30px;\n    height: 30px;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    border-radius: 20px;\n}\n\n.card-region .content-region {\n    margin-left: 10px;\n}\n\n.card-region .content-region .title-region {\n    font-family: Sansation;\n    font-weight: 700;\n    font-size: 18px;\n    line-height: 100%;\n    letter-spacing: 4%;\n    color: #63666ECF;\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
