import React, { useState, useEffect, ReactNode } from 'react';
import DashboardContext from './DashboardContext';

interface DashboardProviderProps {
  children: ReactNode;
}

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const [selectedDashboard, setSelectedDashboard] = useState({ name: 'main', params: null });

  const changeDashboard = (dashboard: string, params?: any) => {
    setSelectedDashboard({ name: dashboard, params });
  };

  useEffect(() => {
  }, [selectedDashboard]);

  return (
    <DashboardContext.Provider value={{ selectedDashboard, changeDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};
