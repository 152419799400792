import { createContext } from 'react';

interface DashboardContextType {
  selectedDashboard: { name: string; params: any };
  changeDashboard: (dashboard: string, params?: any) => void;
}

const DashboardContext = createContext<DashboardContextType>({
  selectedDashboard: { name: 'main', params: null },
  changeDashboard: () => { },
});

export default DashboardContext;