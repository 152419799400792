import React from 'react';
import Logo from '../../img/logo.png';
import backArrow from '../../img/backArrow.svg';
import './Toolbar.css';

function Toolbar({ title, back, backOnClick = () => { } }) {
  return (
    <section className='custom-toolbar'>
      <img src={Logo} />
      <div className='title'>{title}</div>
      <div className='rigth-content'>
        {back && (
          <div className='back' onClick={backOnClick}>
            <img className='back-img' src={backArrow} />
            volver
          </div>
        )}
      </div>
    </section>
  );
}

export default Toolbar;
