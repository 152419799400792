// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commune-panel-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: #E3EAF7;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.commune-panel-view .commune-content {
    display: flex;
    width: 100%;
    height: calc(100% - 84px);
    align-items: flex-start;
}

.commune-panel-view .commune-content .img-map {
    width: 25%;
    height: 100%;
    background-color: #dbe2f2;
}

.commune-panel-view .commune-content .cards-communes {
    width: 75%;
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./views/Commune/Commune.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,sFAAsF;AAC1F;;AAEA;IACI,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,6BAA6B;AACjC","sourcesContent":[".commune-panel-view {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n    background: #E3EAF7;\n    border-radius: 20px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n}\n\n.commune-panel-view .commune-content {\n    display: flex;\n    width: 100%;\n    height: calc(100% - 84px);\n    align-items: flex-start;\n}\n\n.commune-panel-view .commune-content .img-map {\n    width: 25%;\n    height: 100%;\n    background-color: #dbe2f2;\n}\n\n.commune-panel-view .commune-content .cards-communes {\n    width: 75%;\n    height: auto;\n    max-height: 100%;\n    overflow-y: auto;\n    display: flex;\n    align-items: start;\n    flex-wrap: wrap;\n    justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
