const global_states = {
  0: 'OK',
  1: 'WARNING',
  2: 'CRITICAL',
  3: 'UNKNOWN',
};

const color_status = {
  OK: '#2BDC92',
  CRITICAL: '#ED4C5C',
  NOTDEFINED: '#AEB0B2',
  UNK: '#AEB0B2',
  UNKNOWN: '#AEB0B2',
  WARNING: 'yellow'
};

const number_status = {
  OK: 0,
  CRITICAL: 2,
  NOTDEFINED: 4,
  UNK: 3,
  UNKNOWN: 3,
  WARNING: 1
}

export { color_status, number_status, global_states };
