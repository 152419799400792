// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-panel-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: #E3EAF7;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.main-panel-view .main-content {
    display: flex;
    width: 100%;
    height: calc(100% - 84px);
}

.main-panel-view .main-content .north-side {
    display: flex;
    width: 50%;
    height: 100%;
}

.main-panel-view .main-content .img-map {
    width: 50%;
    height: 100%;
    background-color: #dbe2f2;
}

.main-panel-view .main-content .south-side {
    display: flex;
    width: 50%;
    height: 100%;
}


.main-panel-view .main-content .cards-regions {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./views/Main/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,sFAAsF;AAC1F;;AAEA;IACI,aAAa;IACb,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".main-panel-view {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n    background: #E3EAF7;\n    border-radius: 20px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n}\n\n.main-panel-view .main-content {\n    display: flex;\n    width: 100%;\n    height: calc(100% - 84px);\n}\n\n.main-panel-view .main-content .north-side {\n    display: flex;\n    width: 50%;\n    height: 100%;\n}\n\n.main-panel-view .main-content .img-map {\n    width: 50%;\n    height: 100%;\n    background-color: #dbe2f2;\n}\n\n.main-panel-view .main-content .south-side {\n    display: flex;\n    width: 50%;\n    height: 100%;\n}\n\n\n.main-panel-view .main-content .cards-regions {\n    width: 50%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
