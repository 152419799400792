// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toolbar {
  position: relative;
  width: 100%;
  height: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #EFF3FC;
  border-radius: 20px 20px 0px 0px;
}

.custom-toolbar .title {
  font-family: Sansation;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 4%;
  color: #63666ECF;
}

.custom-toolbar img {
  position: absolute;
  left: 0px;
  width: 290px;
  height: auto;
}

.custom-toolbar .rigth-content {
  position: absolute;
  right: 0px;
}

.custom-toolbar .rigth-content .back {
  color: #A0A1A5;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.custom-toolbar .rigth-content .back .back-img {
  width: 100%;
  height: 100%;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./components/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".custom-toolbar {\n  position: relative;\n  width: 100%;\n  height: 84px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  background: #EFF3FC;\n  border-radius: 20px 20px 0px 0px;\n}\n\n.custom-toolbar .title {\n  font-family: Sansation;\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 100%;\n  letter-spacing: 4%;\n  color: #63666ECF;\n}\n\n.custom-toolbar img {\n  position: absolute;\n  left: 0px;\n  width: 290px;\n  height: auto;\n}\n\n.custom-toolbar .rigth-content {\n  position: absolute;\n  right: 0px;\n}\n\n.custom-toolbar .rigth-content .back {\n  color: #A0A1A5;\n  font-size: 14px;\n  font-weight: 700;\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  cursor: pointer;\n}\n\n.custom-toolbar .rigth-content .back .back-img {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
