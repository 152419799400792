const regions = {
    north: [
        {
            name: 'Región Metropolitana', id: 'metropolitana', branchOffices: [
                { name: 'Clínica Bicentenario', id: 'metropolitana', commune: 'estacionCentral' },
                { name: 'Clínica Bupa', id: 'clinicaBupa', commune: 'laFlorida' },
                { name: 'Clínica Indisa', id: 'clinicaIndisa', commune: 'maipu' },
                { name: 'Clínica Tabancura', id: 'clinicaTabancura', commune: 'peñalolen' },
                { name: 'Clínica Vespucio', id: 'clinicaVespucio', commune: 'laFlorida' },
                { name: 'Sucursal Agustinas', id: 'sucursalStgoCentro', commune: 'santiago' },
                { name: 'Sucursal Huérfanos', id: 'sucursalStgoCentro', commune: 'santiago' },
                { name: 'Sucursal Maipú', id: 'sucursalMaipu', commune: 'maipu' },
            ]
        },
        { name: 'Arica y Parinacota', id: 'arica', branchOffices: [] },
        { name: 'Antofagasta', id: 'antofagasta', branchOffices: [] },
        { name: 'Tarapaca', id: 'tarapaca', branchOffices: [] },
        { name: 'Atacama', id: 'atacama', branchOffices: [] },
        { name: 'Valparaíso', id: 'valparaiso', branchOffices: [] },
    ],
    south: [
        { name: 'Maule', id: 'maule', branchOffices: [] },
        { name: 'Ñuble', id: 'ñuble', branchOffices: [] },
        { name: 'Bío Bío', id: 'biobio', branchOffices: [] },
        { name: 'Los Lagos', id: 'losLagos', branchOffices: [] },
        { name: 'Aysén', id: 'aysen', branchOffices: [] },
    ]
}

export { regions };