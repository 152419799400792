import React from 'react';
import './CardRegions.css';

import { color_status } from '../../utils/dictionaryStates'

function CardRegion({ title, status, imgObject, onHover, onClick, regionId, alerts, statusComponents }) {
    return (
        <div
            className='card-region'
            onMouseEnter={onHover ? () => onHover(regionId, true) : () => { }}
            onMouseLeave={onHover ? () => onHover(regionId, false) : () => { }}
            onClick={onClick}
        >
            <div className='img-region'>
                <img src={imgObject} />
            </div>
            <div className='content-region'>
                <div className='title-region'>{title}</div>
                <div className='alerts-region'></div>
                <div className='alerts-components-region'></div>
            </div>
            <div className='status-region' style={{ backgroundColor: color_status[status] }} />
        </div>
    );
}

export default CardRegion;
