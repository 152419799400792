import React, { useContext } from 'react';
import DashboardContext from '../../context/DashboardContext'

import { icons } from '../../utils/dictionaryIcons';
import { regions } from '../../utils/regions';
import './Region.css'
import Toolbar from '../../components/Toolbar/Toolbar';
import CardRegion from '../../components/CardRegion/CardRegions';
import NorthChileMap from '../../components/Maps/NorthChileMap';
import SouthChileMap from '../../components/Maps/SouthChileMap';

function Region() {
    const { changeDashboard, selectedDashboard } = useContext(DashboardContext);
    const region = selectedDashboard.params.region;

    const northSouth = selectedDashboard.params.northSouth;
    const regionData = regions[northSouth];
    const commune = regionData.find((regionObject) => regionObject.id === region);

    const regionCards = commune.branchOffices.map((item) => {
        return <CardRegion title={item.name} imgObject={icons[item.id]} status={'UNK'} regionId={item.id} onClick={() => changeDashboard('commune', selectedDashboard.params)} />
    })

    let map = <></>;
    if (northSouth === 'north') {
        map = <NorthChileMap highlightedRegion={region} />
    }
    if (northSouth === 'south') {
        map = <SouthChileMap highlightedRegion={region} />
    }

    return (
        <div className='region-panel-view'>
            <Toolbar title={'Estado de internet y Totalpack'} back={true} backOnClick={() => changeDashboard('main', undefined)} />
            <div className='region-content'>
                <div className='img-map'>
                    {map}
                </div>
                <div className='cards-regions'>{regionCards}</div>
            </div>
        </div>
    )
}

export default Region;