import React, { useContext } from 'react'; //, { useEffect, useState } 
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { DashboardProvider } from 'context/DashboardProvider';
import DashboardContext from 'context/DashboardContext';
import './SimplePanel.css'

// @ts-ignore
import Main from '../views/Main/Main';
// @ts-ignore
import Region from '../views/Region/Region';
// @ts-ignore
import Commune from '../views/Commune/Commune';
// @ts-ignore
import BranchOffice from '../views/BranchOffice/BranchOffice';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {

  return (
    <DashboardProvider>
      <PanelContent />
    </DashboardProvider>
  );
};

const PanelContent = () => {
  const { selectedDashboard } = useContext(DashboardContext);

  return (
    <div
      className='panel-wrapper'
      style={{ width: '1800px', height: '1070px', backgroundColor: '#d8e1f5', padding: '15px' }}
    >
      <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css'></link>
      <link href='https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap' rel='stylesheet'></link>

      {selectedDashboard.name === 'main' && <Main />}
      {selectedDashboard.name === 'region' && <Region />}
      {selectedDashboard.name === 'commune' && <Commune />}
      {selectedDashboard.name === 'branchOffice' && <BranchOffice />}


    </div>
  );
};
