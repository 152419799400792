import React, { useContext, useState } from 'react';
import DashboardContext from '../../context/DashboardContext'

import { icons } from '../../utils/dictionaryIcons';
import { color_status } from '../../utils/dictionaryStates';
import { regions } from '../../utils/regions';
import './BranchOffice.css'
import Toolbar from '../../components/Toolbar/Toolbar';
import WhiteContainer from '../../components/WhiteContainer/WhiteContainer';
import MetropolitanRegionMap from '../../components/Maps/MetropolitanRegionMap';
import Line from '../../components/Line/Line';

function BranchOffice() {
    const { changeDashboard, selectedDashboard } = useContext(DashboardContext);

    // const region = selectedDashboard.params.region;
    // const northSouth = selectedDashboard.params.northSouth;
    // const regionData = regions[northSouth];
    const commune = selectedDashboard.params.commune;
    const branchOffice = selectedDashboard.params.branchOffice;

    const elementsDatacenter = [
        <WhiteContainer title='Movistar' icon={icons['Shield']} status='UNKNOWN' />,
        <WhiteContainer title='GTD' icon={icons['Shield']} status='UNKNOWN' />
    ];
    const elementsDevice = [
        <WhiteContainer title='Totem' icon={icons['totem']} status='UNKNOWN' />,
        <WhiteContainer title='Nano PC' icon={icons['switch']} status='UNKNOWN' />
    ]
    const elementsBranchOffice = [
        <WhiteContainer title='Internet' icon={icons['wifi']} noHover={true} style={{ border: 'dashed 1px #A0A1A5', width: '45%' }} content={elementsDatacenter} />,
        <WhiteContainer title='Dispositivos' icon={icons['pc']} noHover={true} style={{ border: 'dashed 1px #A0A1A5', width: '45%' }} content={elementsDevice} />
    ];

    const arrayArrows = [
        <Line type='horizontal' size={180} style={{top: 240, left: 379}}/>,
        <Line type='vertical' size={190} style={{top: 240, left: 559}}/>,
        <Line type='horizontal' size={132} style={{top: 430, left: 559}} showArrowEnd={true}/>,

        <Line type='horizontal' size={160} style={{top: 308, left: 379}}/>,
        <Line type='vertical' size={170} style={{top: 308, left: 539}}/>,
        <Line type='horizontal' size={152} style={{top: 478, left: 539}} showArrowEnd={true}/>,

        <Line type='horizontal' size={160} style={{top: 596, left: 379}}/>,
        <Line type='vertical' size={64} style={{top: 535, left: 539}}/>,
        <Line type='horizontal' size={152} style={{top: 535, left: 539}} showArrowEnd={true}/>,

        <Line type='horizontal' size={180} style={{top: 658, left: 379}}/>,
        <Line type='vertical' size={106} style={{top: 555, left: 559}}/>,
        <Line type='horizontal' size={132} style={{top: 555, left: 559}} showArrowEnd={true}/>,

    ]

    return (
        <div className='branch-office-panel-view'>
            <Toolbar title='Estado de internet y Totalpack' back={true} backOnClick={() => changeDashboard('commune', selectedDashboard.params)} />
            <div className='branch-office-content'>
                <div className='img-map'>
                    <MetropolitanRegionMap highlightedRegion={commune} />
                </div>
                <div className='connections'>
                    <div className='connections-title'>Sucursal {branchOffice}</div>
                    <div className='connections-scheme'>
                        <WhiteContainer
                            title='Internet Teatinos'
                            icon={icons['Datacenter']}
                            content={elementsDatacenter}
                            noHover={true}
                            style={{
                                position: 'absolute',
                                width: '300px',
                                top: '150px',
                                left: '80px',
                            }}
                        />
                        <WhiteContainer
                            title='Internet Quilicura'
                            icon={icons['Datacenter']}
                            content={elementsDatacenter}
                            noHover={true}
                            style={{
                                position: 'absolute',
                                width: '300px',
                                top: '500px',
                                left: '80px',
                            }}
                        />
                        <WhiteContainer
                            title={branchOffice}
                            icon={icons['building']}
                            content={elementsBranchOffice}
                            noHover={true}
                            style={{
                                position: 'absolute',
                                width: '500px',
                                top: '350px',
                                left: '700px',
                            }}
                            contentRow={true}
                        />
                        {arrayArrows}
                    </div>
                    <div className='connections-footer'>
                        <div className='simbology-title'>Simbología</div>
                        <div className='simbology-items'>
                            <div className='simbology-item'>
                                <div className='status' style={{ backgroundColor: color_status['OK'], marginRight: 5 }} />
                                Operativo
                            </div>
                            <div className='simbology-item'>
                                <div className='status' style={{ backgroundColor: color_status['WARNING'], marginRight: 5 }} />
                                Advertencia
                            </div>
                            <div className='simbology-item'>
                                <div className='status' style={{ backgroundColor: color_status['CRITICAL'], marginRight: 5 }} />
                                Fuera de servicio
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchOffice;