import Logo from '../img/logo.png';
import Fortinet from '../img/Fortinet.png';
import Shield from '../img/shield.png';
import antofagasta from '../img/antofagasta.png'
import arica from '../img/arica.png'
import aysen from '../img/aysen.png'
import consaludWhite from '../img/consalud-white.jpg'
import atacama from '../img/copiapo.png'
import losLagos from '../img/los_lagos.png'
import maule from '../img/maule.jpg'
import metropolitana from '../img/metropolitana.jpg'
import ñuble from '../img/ñuble.png'
import tarapaca from '../img/tarapaca.png'
import valparaiso from '../img/valparaiso.jpg'
import clinicaBupa from '../img/clinica_bupa.jpg'
import clinicaIndisa from '../img/clinica_indisa.jpg'
import clinicaTabancura from '../img/clinica_tabancura.jpg'
import clinicaVespucio from '../img/clinica_vespucio.jpg'
import sucursalMaipu from '../img/sucursal_maipu.jpg'
import sucursalStgoCentro from '../img/sucursal_stgo_centro.jpg'
import Datacenter from '../img/Datacenter.png'
import building from '../img/building.png'
import wifi from '../img/wifi.png'
import pc from '../img/pc.png'
import Switch from '../img/switch.png'
import totem from '../img/totem.png'


const icons = {
  logo: Logo,
  Fortinet: Fortinet,
  Shield: Shield,
  antofagasta: antofagasta,
  arica: arica,
  aysen: aysen,
  consaludWhite: consaludWhite,
  biobio: consaludWhite,
  atacama: atacama,
  losLagos: losLagos,
  maule: maule,
  metropolitana: metropolitana,
  ñuble: ñuble,
  tarapaca: tarapaca,
  valparaiso: valparaiso,
  clinicaBupa: clinicaBupa,
  clinicaIndisa: clinicaIndisa,
  clinicaTabancura: clinicaTabancura,
  clinicaVespucio: clinicaVespucio,
  sucursalMaipu: sucursalMaipu,
  sucursalStgoCentro: sucursalStgoCentro,
  Datacenter: Datacenter,
  building: building,
  wifi: wifi,
  pc: pc,
  switch: Switch,
  totem: totem
};



export { icons };
